import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("select-filter", {
    key: "TruckCode",
    attrs: {
      "label": "Truck",
      "placeholder": "Select Truck",
      "reference": "grower.common.trucks",
      "source": "code",
      "source-label": _vm.genTrucksLabel
    }
  }), _c("select-filter", {
    key: "GrowerId",
    attrs: {
      "label": "Grower",
      "placeholder": "Select Grower",
      "reference": "grower.common.growers",
      "source": "id",
      "source-label": _vm.genGrowerLabel
    }
  }), _c("select-filter", {
    key: "CropId",
    attrs: {
      "label": "Crop Code",
      "placeholder": "Select Crop Code",
      "reference": "grower.common.crops",
      "source": "id",
      "source-label": _vm.genCropCodeLabel
    }
  }), _c("select-filter", {
    key: "ContractId",
    attrs: {
      "label": "Contracts",
      "placeholder": "Select Contracts",
      "reference": "grower.contracts",
      "source": "id",
      "source-label": _vm.genContractLabel,
      "search-by": "Code",
      "lazy": ""
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterIntakeSample",
  methods: {
    genTrucksLabel(option) {
      return `${option.id} - ${option.code}`;
    },
    genCropCodeLabel(option) {
      return `${option.id} - ${option.name}`;
    },
    genGrowerLabel(option) {
      return `${option.id} - ${option.name}`;
    },
    genContractLabel(option) {
      return `${option.id} - ${option.code}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterIntakeSample = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "filter-attribute"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.trucks",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.growers",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.contracts",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.intake-samples",
      "api-url": _vm.apiUrl,
      "redirect-route": "/watties-grower/intake-samples"
    }
  }, [_c("filter-intake-sample")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    FilterIntakeSample
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
